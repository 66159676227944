import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch } from "../hooks/hook";
import { useTranslation } from "react-i18next";
import { pushPopup } from "../reducers/popupSlice";
import contactService from "../services/contact";

export const FormContactFooter = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      fullname: "",
      phone: "",
      numOfPeople: "",
      content: ""
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required(t("validate.error.required")),
      phone: Yup.string()
              .required(t("validate.error.required"))
              .matches(/^[0-9]{10}$/, t("validate.error.pattern", {name: t("contact.form.phone")})),
      numOfPeople: Yup.string()
              .required(t("validate.error.required"))
              .matches(/^\d+$/, t("validate.error.number")),
      content: Yup.string().required(t("validate.error.required")),
    }),
    onSubmit: (values) => {
      if(values.fullname.trim() !== "" && values.content.trim() !== "" && values.phone.trim() !== "" && values.numOfPeople.trim() !== "") {
        contactService.postContact(values).then(()=> {
          dispatch(pushPopup({
                  type: "SUCCESS",                         
                  message: t("contact.form.postSuccess")
          }))

          formik.resetForm();
        }).catch(()=> {
                dispatch(pushPopup({
                        type: "ERROR",
                        message: t("contact.form.postFail")
                }))
        })
      } 
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="mt-[16px] text-inherit sc991:flex  sc991:flex-col text-normalgit"
    >
      <div>
        <input
          type="text"
          name="fullname"
          placeholder={t("contact.form.name")}
          value={formik.values.fullname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="text-white placeholder:text-white w-full outline-none border-b bg-transparent border-b-white focus:border-b-lightGreen duration-200"
        />
        <span className="text-[14px] text-white mt-1">
          {formik.touched.fullname && formik.errors.fullname}
        </span>
      </div>

      <div className="mt-[16px]">
        <input
          type="text"
          name="phone"
          placeholder={t("contact.form.phone")}
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="text-white placeholder:text-white  w-full  outline-none border-b bg-transparent border-b-white focus:border-b-lightGreen duration-200"
        />
        <span className="text-[14px] text-white mt-1">
          {formik.touched.phone && formik.errors.phone}
        </span>
      </div>

      <div className="mt-[16px]">
        <input
          type="text"
          name="numOfPeople"
          placeholder={t("contact.form.numOfPeople")}
          value={formik.values.numOfPeople}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="text-white placeholder:text-white w-full outline-none border-b bg-transparent border-b-white focus:border-b-lightGreen duration-200"
        />
        <span className="text-[14px] text-white mt-1">
          {formik.touched.numOfPeople && formik.errors.numOfPeople}
        </span>
      </div>

      <div className="mt-[16px]">
        <input
          type="text"
          name="content"
          value={formik.values.content}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={t("contact.form.content_request")}
          className="text-white placeholder:text-white  w-full outline-none border-b bg-transparent border-b-white focus:border-b-lightGreen duration-200"
        />
        <span className="text-[14px] text-white mt-1">
          {formik.touched.content && formik.errors.content}
        </span>
      </div>
      <div className="mt-[16px]">
        <button className="btn-footer w-[134px] h-[24px] rounded lg:w-[210px] lg:h-[40px] lg:rounded-[10px] text-[12px] lg:text-[18px] bg-primary after:top-1 after:left-1 lg:after:top-[7px] lg:after:left-[7px]" type="submit">
          {t("home.banner.button")}
        </button>
      </div>
    </form>
  );
};
