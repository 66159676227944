import { ROLE_ADMIN, ROLE_USER } from "../types";
import { hostBE } from "../types/host";

export const listLink = [
  {
    urlImage: `${hostBE}/fe/nav1.png`,
    path: "/quanly/taikhoan",
    role: ROLE_USER,
    content: "header.menu.menuManager.account",
  },
  {
    urlImage: `${hostBE}/fe/nav2.png`,
    path: "/quanly/trangchu",
    role: ROLE_USER,
    content: "header.menu.menuManager.homeManager",
  },
  {
    urlImage: `${hostBE}/fe/nav3.png`,
    path: "/quanly/sanpham",
    role: ROLE_USER,
    content: "header.menu.menuManager.productManager",
  },
  {
    urlImage: `${hostBE}/fe/projecmananger.png`,
    path: "/quanly/du_an",
    role: ROLE_USER,
    content: "header.menu.menuManager.Project",
  },
  {
    urlImage: `${hostBE}/fe/nav5.png`,
    path: "/quanly/lienhe",
    role: ROLE_USER,
    content: "header.menu.menuManager.contactManager",
  },
  {
    urlImage: `${hostBE}/fe/nav6.png`,
    path: "/quanly/tuvan",
    role: ROLE_USER,
    content: "header.menu.menuManager.advise",
  },
  {
    urlImage: `${hostBE}/fe/nav7.png`,
    path: "/quanly/thuvien",
    role: ROLE_USER,

    content: "header.menu.menuManager.imageManager",
  },
  {
    urlImage: `${hostBE}/fe/nav10.png`,
    path: "/quanly/thongtintaikhoan",
    role: ROLE_USER,
    content: "header.menu.menuManager.accountInfo",
  },
  {
    urlImage: `${hostBE}/fe/history_sidebar.png`,
    path: "/quanly/history",
    role: ROLE_USER,
    content: "header.menu.menuManager.historyDevelopment",
  },
];
