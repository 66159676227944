import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../configs/redux/store';
import { hideModal } from '../reducers/modal';
import { pushPopup } from '../reducers/popupSlice';
import { editProjecSuccess, editProject, getProject, getProjectFail, getProjectSuccess, searchProject, searchProjectSuccess,putPriorityProject, putPrioritySuccess } from '../reducers/projectmanagerSlice';
import { TranslateState } from '../reducers/translate';
import projectServiece from '../services/project';
import { projecResult, projecType, searchProjecParam } from '../typeProps/Productype';
import { Option } from './../types';


function* getProjectSaga({payload}: PayloadAction<Option>){
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
    try {
        const res: projecResult = yield call(projectServiece.get,payload);
        yield put(getProjectSuccess(res))
    } catch (error) {
        yield put(getProjectFail(isEnglish ? "Không hiển thị được danh sách cơ sở vật chất" : "Unable to display project list"))
    }
}

function* searchProjectSaga({payload}: PayloadAction<searchProjecParam>){
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
    try {
        const res : projecResult = yield call(projectServiece.searchProject, payload);
        yield put(searchProjectSuccess(res))
        
    } catch (error) {
        yield put(getProjectFail(isEnglish ? "Không tìm thấy cơ sở vật chất nào" : "No projects found"))
    }
}

function* editProjecSaga({payload}: PayloadAction<projecType>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
    try {
        const res: projecType = yield call(projectServiece.editProject,payload);
        yield put(editProjecSuccess(res))
        yield put( pushPopup({
            type: "SUCCESS",
            message: isEnglish ? "Sửa cơ sở vật chất thành công." : "Successful project editing.",
          }))

          yield put(hideModal());
    } catch (error) {
        yield put(
            pushPopup({
                type: "WARNING",
                message: isEnglish ? "Không sửa được cơ sở vật chất!" : "Unable to edit project!",
              })
        )
    }
}

function* putPriorityProjectSaga ({payload}:PayloadAction<number>){
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
   try {
    const res:projecType= yield call(projectServiece.putProject,payload);
    yield put(putPrioritySuccess(res))
    yield put(
        pushPopup({
          type: "SUCCESS",
          message: isEnglish ? "Thay đổi trạng thái cơ sở vật chất  thành công" : "Project status change successful",
        })
      )
   } catch (error) {
    yield put(
        pushPopup({
          type: "WARNING",
          message: isEnglish ? "Tối đa 6 cơ sở vật chất tiêu biểu" : "Up to 6 featured project",
        })
      );
   }
}


export default function* projectManagerSagaMid(){
    yield takeLatest(getProject.type,getProjectSaga);
    yield takeLatest(searchProject.type,searchProjectSaga);
    yield takeLatest(editProject.type,editProjecSaga);
    yield takeLatest(putPriorityProject.type,putPriorityProjectSaga);
}