import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hook";

type propsSearch = {
  titleItem: {
    id: number;
    titleEn: string;
    titleVi: string;
    type: string;
    descriptionEn: string;
    descriptionVi: string;
    categoryEn: string;
    categoryVi: string;
    avatarUrl?: string;
  };
};
function SearchItem({ titleItem }: propsSearch) {
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formatString = (stringFormat: string, numberEnd: number) => {
    if (stringFormat.length < numberEnd) {
      return stringFormat;
    } else {
      const newString = stringFormat.slice(0, numberEnd) + "...";
      return newString;
    }
  };
  const handleforword = (keyWordS: string, id: number) => {
    switch (keyWordS) {
      case "Project":
        navigate(`/mon-an/${id}`);
        break;

      case "Recruit":
        navigate(`/tuyen-dung/${id}`);
        break;
      case "Product":
        navigate(`/chitietsanpham/${id}`);
        break;
      case "News":
        navigate(`/tin-tuc/${id}`);
        break;
      default:
        break;
    }
  };
  return (
    <div className="w-full rounded-md mb-5 bg-white search_item cursor-pointer">
      <div
        className="w-full flex flex-wrap"
        onClick={() => handleforword(titleItem.categoryEn, titleItem.id)}
      >
        <div className="2xl:w-1/5 m992:w-[30%] sm:w-1/4 w-full h-[150px] md:mb-0 mb-5">
          <img
            src={
              titleItem.avatarUrl
                ? titleItem.avatarUrl
                : "images/banner_image.png"
            }
            alt=""
            className="w-full h-full md:rounded-l-md md:rounded-r-none rounded-b-none rounded-t-md"
          />
        </div>
        <div className=" 2xl:w-[80%] m992:w-[70%] sm:w-3/4 w-full py-2 m992:pl-8 pl-3 m992:pr-5 pr-3 flex flex-col justify-center">
          <p className="text-base font-bold 2xl:mb-3 mb-2 hover:text-primary line-clamp-1">
            {isEnglish ? titleItem.titleVi : titleItem.titleEn}
          </p>
          <p className="text-base mb-2">
            {t("search.category")}
            <span className="font-bold text-base">
              {isEnglish ? titleItem.categoryVi : titleItem.categoryEn}
            </span>
          </p>
          <p className="text-base 2xl:mb-3 mb-2 line-clamp-1">
            {isEnglish ? titleItem.descriptionVi : titleItem.descriptionEn}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SearchItem;
