import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AiOutlineMail } from "react-icons/ai"
import { FaFacebookF } from "react-icons/fa"
import { GoLocation } from "react-icons/go"
import {
  MdLocationOn,
  MdOutlineFace,
  MdOutlineFacebook,
  MdOutlineLanguage,
  MdOutlinePhone,
} from "react-icons/md"
import { ButtonImage } from "../components/ButtonImage"
import { FormContactFooter } from "../components/FormContactFooter"
import Map from "../components/Map"
import MapPlace from "../components/MapPlace"
import ModalMap from "../components/ModalMap"
import { useAppDispatch } from "../hooks/hook"
import { showModal } from "../reducers/modal"
import { hostBE } from "../types/host"

export default function Footer2() {
  const [path, setPath] = useState<String>("")

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const showModalMap = () => {
    dispatch(
      showModal(<ModalMap Map={<Map width={"100%"} height={"100%"} />} />)
    )
  }

  const showModalMapPlace = () => {
    dispatch(
      showModal(<ModalMap Map={<MapPlace width={"100%"} height={"100%"} />} />)
    )
  }

  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  return (
    <div className="lssm:px-[24px] md:px-[80px] lg:pb-[40px]  lg:px-[100px] 2xl:px-[221px]  bg-primary text-white text-white-color sc991:text-white-color overflow-x-hidden ">
      <div className="flex lssm:flex-col lg:flex-row lg:gap-x-[50px] 2xl:gap-x-[150px] lssm:text-[12px] lg:text-[18px] relative pb-[24px] lssm:pt-[30px] lssm:pb-[60px] lg:pb-[30px] lg:pt-[50px] ">
        <div className="h-full flex-1">
          <div className="rounded-[5px]">
            <img
              className="sc991:w-[120px] w-auto h-20"
              src={`${hostBE}/fe/anficoLogo.png`}
              alt="logo"
            />
          </div>
          <div className="flex items-center lg:items-baseline mt-5">
            <span className="text-inherit mr-5">
              <GoLocation />
            </span>
            <span className="w-fit">{t("footer.footer_1.content1")}</span>
          </div>
          <div className="flex items-center mt-5">
            <span className="text-inherit text-xl mr-5">
              <MdLocationOn />
            </span>
            <ButtonImage
              onClick={showModalMap}
              text="Xem bản đồ"
              className="lssm:w-[95px] lssm:h-[21px] lg:w-[125px] lg:h-[30px] lssm:text-[12px] lg:text-[16px] !text-darkBlue bg-white rounded-[5px] text-black"
            />
          </div>
          <p className="text-white text-[10px] w-max uppercase hidden mt-[24px]  lg:block">
            <span className="font-bold">@2023 SMESTORE </span>- A PRODUCT OF
            ALADIN TECHNOLOGY
          </p>
        </div>

        <div className="w-max lssm:mt-[24px] lg:mt-0">
          <h3 className="text-[12px] font-medium lg:text-normal1 lg:text-[20px] lg:font-semibold text-inherit w-full">
            {t("home.topic.contact1")}
          </h3>
          <div className="lssm:mt-[7px] lg:mt-[32px] text-inherit sc991:flex sc991:flex-col">
            <div className="flex ">
              <span className="text-inherit text-xl w-[20px]">
                <MdOutlinePhone />
              </span>
              <a href="tel:0975112828" className="ml-5 mr-2 cursor-pointer">
                {t("footer.footer_1.content2")}
              </a>{" "}
              /
              <a href="tel:0777291919" className="ml-2 cursor-pointer">
                {t("footer.footer_1.content3")}
              </a>
            </div>
            <div className="flex mt-5 items-center">
              <span className="text-inherit text-xl w-[20px]">
                <AiOutlineMail />
              </span>
              <a
                href={`mailto:${t("footer.footer_1.content4")}`}
                target="blank"
                className="ml-5"
              >
                {t("footer.footer_1.content4")}
              </a>{" "}
            </div>
            <div className="flex mt-5 items-center">
              <span className="text-inherit text-xl w-[20px]">
                <MdOutlineLanguage />
              </span>
              <a
                href={t("footer.footer_1.content5")}
                target="blank"
                className="ml-5"
              >
                {t("footer.footer_1.content5")}
              </a>
            </div>
            <div className="flex mt-5 items-center">
              <span className="text-inherit text-xl w-[20px] flex justify-center">
                <FaFacebookF />
              </span>
              <a
                href={t("footer.footer_1.content6")}
                target="blank"
                className="ml-5"
              >
                {t("footer.footer_1.content6")}
              </a>
            </div>
            {/* <div className="flex mt-5 items-center"><span className="text-inherit text-xl"><img src="/images/phone-icon.png" alt="" /></span> <Button onClick={showModalMap} color="empty" className="rounded-[10px] sc>768:text-px13 bg-white-color ml-5 text-text-primary">{t("footer.see_map")}</Button> </div> */}
          </div>
        </div>

        <div className="w-max lssm:mt-[30px] lg:mt-0">
          <h3 className="text-[12px] font-medium lg:text-normal1 lg:text-[20px]  lg:font-semibold text-inherit uppercase">
            {t("home.topic.contact2")}
          </h3>
          <p className="text-[12px]">{t("home.topic.contact3")}</p>
          <FormContactFooter />
        </div>

        <p className="text-white text-[10px] w-max uppercase absolute lssm:block lg:hidden lssm:bottom-[15px] xl:bottom-[0px] lssm:left-[50%] lssm:translate-x-[-50%] xl:translate-x-0 xl:left-0">
          <span className="font-bold">@2023 SMESTORE </span>- A PRODUCT OF
          ALADIN TECHNOLOGY
        </p>
      </div>
    </div>
  )
}
