import clsx from "clsx";
import React from "react";
type Props = {
  image?: string;
  text: string;
  className?: string;
  onClick?: any;
  type?: "submit" | "button";
};
export const ButtonImage = ({
  image,
  text,
  className,
  onClick,
  type,
}: Props) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        "bg-lightGreen  h-[60px] rounded-[10px] flex justify-center items-center text-white " +
          className
      )}
    >
      {image && (
        <span className="mr-[9px]">
          <img src={image} alt={image} />
        </span>
      )}
      <span className="text-inherit w-fit">{text}</span>
    </button>
  );
};
