
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Slider from "../../components/Home/Slider";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getPartner } from "../../reducers/partner";


export default function SlideCompany() {

    const dispatch = useAppDispatch();
    const partner = useAppSelector(state => state.partnerSlice)
    const [t] = useTranslation();

    useEffect(()=> {
        dispatch(getPartner())
    }, [])

    return (
        <div className="md:h-[356px] lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] sc>768:bg-primary sc>768:py-[30px]   flex flex-col">
            <div className="lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px] font-bold uppercase border-l-[25px] sc>768:mb-[30px]  text-[#11213F]   sc>768:text-center sc>768:border-none border-solid border-primary leading-[35px] pl-[20px]">
               {t('home.topic.topic4')}
            </div>
            { partner.partner.length > 0 ? <Slider/> : <div></div> }
         </div> 
    )
}