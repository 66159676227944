type Props = {
  width: number | string;
  height: number | string;
};

export default function Map({ width, height }: Props) {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.799164682339!2d105.8018103!3d21.000686200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135aca33263ff0d%3A0x1e2ad065474011d0!2zMjAyIMSQLiBOZ3V54buFbiBUdcOibiwgTmjDom4gQ2jDrW5oLCBUaGFuaCBYdcOibiwgSMOgIE7hu5lp!5e0!3m2!1svi!2s!4v1690338452658!5m2!1svi!2s"
      width={width}
      height={height}
    ></iframe>
  );
}
