import { useTranslation } from "react-i18next";
import Map from "../components/Map";
import {GoLocation} from "react-icons/go"
import {MdOutlinePhone, MdOutlineLanguage, MdLocationOn} from "react-icons/md"
import Button from "../components/Button";
import { useAppDispatch } from "../hooks/hook";
import { showModal } from "../reducers/modal";
import ModalMap from "../components/ModalMap";
import MapPlace from "../components/MapPlace";
import { AiOutlineMail } from "react-icons/ai";
import { hostBE } from "../types/host";
import { FormContactFooter } from "../components/FormContactFooter";

export default function Footer () {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const showModalMap = () => {
        dispatch(showModal(<ModalMap Map={<Map width={"100%"} height={"100%"}  />} />))
    }

    const showModalMapPlace = () => {
        dispatch(showModal(<ModalMap Map={<MapPlace width={"100%"} height={"100%"}  />} />))
    }

    return (
        <>
     
        <div className="lssm:px-[24px] py-[60px] md:pl-[100px] xl:pl-[50px]  bg-primary text-white-color sc991:text-white-color sc991:bg-primary overflow-x-hidden relative">
               <div className="grid lssm:grid-cols-1 lg:grid-cols-5 lssm:text-px13 sm:text-px16">

                    <div className="border-r h-full my-auto sc991:mx-auto lg:pr-[24px] 2xl:pr-[57px] flex items-center justify-center border-solid border-white-color sc991:border-none lg:py-[55px]">
                        <div className="px-[10px] py-[15px]  bg-white-color w-[266px] sc>768:mx-auto flex justify-center rounded-[5px]">
                            <img  src={`${hostBE}/fe/anficoLogo.png`} className="w-[80%]" alt="logo" />
                        </div>
                            {/* <p className="mt-[12px]  text-text24 font-bold text-center">{t("footer.slogan")}</p> */}
                    </div>

                    <div className="lssm:col-span-1 lg:col-span-2  sc991:mt-[32px] lssm:pb-[24px] lg:py-[55px] lssm:px-[0] md:px-[32px] xl:px-[40px] 2xl:px-[57px] border-r border-solid border-white-color sc991:border-none">
                        <h3 className="text-px16  font-bold text-inherit w-full sc991:text-center">{t("footer.footer_1.title")}</h3>
                        <div className="mt-[32px] text-inherit sc991:flex sc991:flex-col">
                            <div className="flex items-center"><span className="text-inherit text-xl"><GoLocation /></span><span className="ml-5 ">{t("footer.footer_1.content1")}</span> </div>
                            <div className="flex mt-5"><span className="text-inherit text-xl"><MdOutlinePhone /></span>
                            <a href="tel:0975112828" className="ml-5 cursor-pointer">{t("footer.footer_1.content2")}</a> /
                            <a href="tel:0777291919" className="ml-5 cursor-pointer">{t("footer.footer_1.content3")}</a>
                            </div>
                            <div className="flex mt-5"><span className="text-inherit text-xl"><AiOutlineMail /></span> <a href={`mailto:${t("footer.footer_1.content4")}`} target="blank" className="ml-5">{t("footer.footer_1.content4")}</a> </div>
                            <div className="flex mt-5"><span className="text-inherit text-xl"><MdOutlineLanguage /></span> <a href={t("footer.footer_1.content5")} target="blank" className="ml-5">{t("footer.footer_1.content5")}</a> </div>
                            <div className="flex mt-5 items-center"><span className="text-inherit text-xl"><MdLocationOn /></span> <Button onClick={showModalMap} color="empty" className="rounded-[10px] sc>768:text-px13 bg-white-color ml-5 text-text-primary">{t("footer.see_map")}</Button> </div>
                        </div>
                    
                    </div>

                    <div className="lssm:col-span-1 lg:col-span-2  sc991:pt-[32px]  lssm:pb-[24px] lg:py-[55px] sc991:border-t sc991:border-solid sc991:border-white-color lssm:pl-[0] md:pl-[32px] xl:pl-[40px] 2xl:pl-[57px] ">
                    <div className="w-max lssm:mt-[30px] lg:mt-0">
                        <h3 className="text-[12px] font-medium lg:text-normal1  lg:font-semibold text-inherit uppercase">
                            ĐĂNG KÍ ĐẶT BÀN
                        </h3>
                        <p className="text-[12px]">
                            Thông tin khách hàng sẽ được chúng tôi tuyệt đối bảo mật.
                        </p>
                            <FormContactFooter />
                        </div>
                    
                    </div>

                 
                </div> 
                <p className="absolute bottom-4 left-[50%] translate-x-[-50%] lssm:text-[10px] md:text-px12 text-center w-full px-[24px]">Restaurant Name, Copyright @2022  - Design by Aladin Technology Company</p>
        </div>
        </>
    )

}