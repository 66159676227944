import clsx from "clsx";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CgArrowLongRight } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import useInView from "../../hooks/useInView";
import { changeCategoryChecked } from "../../reducers/categorySlice";
import { getProductActiveHome } from "../../reducers/productPublic";
import { hostBE } from "../../types/host";

import Button from "../Button";

export default function TopicProduct() {
  const { ref, isInView } = useInView();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translate = useAppSelector((state) => state.translateSlice);
  const categories = useAppSelector(
    (state) => state.productPublic.productActiveHome
  );
  const [t] = useTranslation();

  const handleNavigate = (id: number) => {
    navigate("/sanpham");
    dispatch(changeCategoryChecked(id));
  };
  useEffect(() => {
    // if (productActiveHome.length === 0) {
    dispatch(getProductActiveHome());
    // }
  }, []);

  return (
    <div
      className="lssm:py-[32px] 2xl:py-[78px] h-full lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] "
      ref={ref}
    >
      <div className="grid grid-cols-3 w-full h-full">
        <div
          className={clsx(
            "lssm:col-span-3 xl:col-span-2 xl:mr-[60px] 2xl:mr-[117px]",
            { "animate__animated animate__fadeInLeft": isInView }
          )}
        >
          <img src={`${hostBE}/fe/image_project.png`} className="w-full" alt="" style={{width: "100%", height: "100%",  objectFit: "cover"}} />
        </div>
        <div className="lssm:col-span-3 xl:col-span-1">
          <h4 className="font-semibold lssm:text-px14 md:text-px16 text-center lg:text-[32px] text-white-color lssm:my-[24px] lg:mb-[47px]" style={{marginTop: "0px"}}>
            {t("home.supplier")}
          </h4>
          <div className="grid lssm:grid-cols-2  sm-480:grid-cols-4 gap-x-[46px] lg:gap-x-[21px] gap-y-[32px]">
            {categories.slice(0, 12).map((item) => {
              return (
                <div key={item.id} onClick={() => handleNavigate(item.id)}>
                  <div
                    className={clsx(
                      "w-[133px] max-w-full h-[137px] rounded-[10px] border-2 border-solid border-white-color flex items-center justify-center cursor-pointer",
                      { "animate__animated animate__fadeInDown": isInView }
                    )}
                  >
                    <img src={item.imageUrl} alt="" style={{width: "100%", height: "75%",  objectFit: "cover"}} />
                  </div>
                  <p
                    className={clsx(
                      "text-px14  lg:text-px20 line-clamp-2  text-white-color mt-[14px] cursor-pointer",
                      { "animate__animated animate__flash": isInView }
                    )}
                  >
                    {translate.isEnglish ? item.nameVi : item.nameEn}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
